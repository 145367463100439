import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware, navigateTo, useBabiesStore } from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
    const auth = useAuthStore()
    const babies = useBabiesStore()

    if (auth.authenticated && !auth.user) {
        await auth.fetchUser()
    }

    if (auth.authenticated && !babies.babies.length) {
        await babies.fetchBabies()
    }

    if ((!auth.authenticated || !auth.user) && to.meta.layout !== 'auth') {
        return navigateTo('/auth/login')
    }
})
