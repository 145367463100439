import { api } from './api'

const BabyRepository = {
    index () {
        return api().$get('/parent/baby')
    },

    update (babyUuid, payload) {
        return api().$put(`/parent/baby/${babyUuid}`, payload)
    },

    addressChangeDemand (registrationUuid, payload) {
        return api().$post(`/parent/registrations/${registrationUuid}/baby/address/demand`, payload)
    },

    measureChangeDemand (registrationUuid, payload) {
        return api().$post(`/parent/registrations/${registrationUuid}/measures`, payload)
    }
}

export default BabyRepository
