import { mapState } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { useBabiesStore } from '~/stores/babies.js'

export default {
    computed: {
        ...mapState(useAuthStore, {
            $user: 'user'
        }),
        ...mapState(useBabiesStore, {
            $currentBaby: 'currentBaby'
        })
    }
}
