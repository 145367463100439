import payload_plugin_qLmFnukI99 from "/home/ploi/my.abcreche.com/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/ploi/my.abcreche.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ploi/my.abcreche.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ploi/my.abcreche.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JorSLPZXKr from "/home/ploi/my.abcreche.com/node_modules/nuxt-umami/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/ploi/my.abcreche.com/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/home/ploi/my.abcreche.com/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_PSbK9A48ZQ from "/home/ploi/my.abcreche.com/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import echo_E4VcyfBaFS from "/home/ploi/my.abcreche.com/plugins/echo.js";
import globalMixin_2fnQuakdXd from "/home/ploi/my.abcreche.com/plugins/globalMixin.js";
import pinia_Uphuq97G1L from "/home/ploi/my.abcreche.com/plugins/pinia.js";
import ui_Xae0mHBLH9 from "/home/ploi/my.abcreche.com/plugins/ui.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_JorSLPZXKr,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_PSbK9A48ZQ,
  echo_E4VcyfBaFS,
  globalMixin_2fnQuakdXd,
  pinia_Uphuq97G1L,
  ui_Xae0mHBLH9
]