import mitt from 'mitt'
import {
    directives,
    filters,
    registerToastPlugin,
    registerDialogPlugin,
    registerModalPlugin
} from 'abcreche-ui'
import dayjs from 'dayjs'
import { defineNuxtPlugin } from '#app'
import 'dayjs/locale/fr'

window.ui_locale = 'fr'

window.dayjs = dayjs
window.dayjs.locale('fr')

export default defineNuxtPlugin((nuxtApp) => {
    /**
     * Emitter event bus
     */
    const emitter = mitt()
    nuxtApp.vueApp.config.globalProperties.emitter = emitter

    /**
     * Filters
     */
    nuxtApp.vueApp.config.globalProperties.$filters = filters

    /**
     * Directives
     */
    directives(nuxtApp.vueApp)

    /**
     * Modal, Toast, Dialog
     */
    registerToastPlugin(nuxtApp.vueApp)
    registerDialogPlugin(nuxtApp.vueApp)
    registerModalPlugin(nuxtApp.vueApp)

    return {
        provide: {
            emitter
        }
    }
})
