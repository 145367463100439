import { defineStore } from 'pinia'
import BabyRepository from '~/repositories/BabyRepository.js'

export const useBabiesStore = defineStore('babies', {
    state: () => ({
        babies: [],
        currentBabyUuid: null
    }),

    actions: {
        fetchBabies () {
            return BabyRepository.index()
                .then((response) => {
                    this.babies = response.data
                    if (!this.currentBabyUuid && this.babies?.length > 0) {
                        this.setCurrentBabyUuid(this.babies[0].uuid)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        setCurrentBabyUuid (babyUuid) {
            this.currentBabyUuid = babyUuid
        },

        refreshCurrentBaby () {
            return this.fetchBabies()
        }
    },

    getters: {
        currentBaby () {
            return this.babies.find(baby => baby.uuid === this.currentBabyUuid)
        }
    },

    persist: {
        pick: ['currentBabyUuid']
    }
})
